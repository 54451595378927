.sidebar {
  padding: 0;
  margin-top: 20px;

  @media (min-width: $grid-float-breakpoint) {
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 10px;
  }

  .sidebox {
    border-radius: $border-radius-small;
    margin-bottom: 20px;
    padding: 0 15px;
    box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, .15);
  }

  .main & {
    top: -10px;
  }

  .widget {
    padding: 20px 0 10px;
    border-bottom: 1px solid $hr-color;

    &:last-child{
      border: none;
    }

    @media (min-width: $grid-float-breakpoint) {
      .widget-content {
        padding: 0;
      }
    }
  }

  ol, ul {
    padding-left: 0;
    list-style-type: none;

    li {
      a {
        display: block;
        height: 29px;
        line-height: 29px;
        font-size: 13px;
        margin-bottom: 1px;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $brand-primary;
        }
      }
    }
  }
}

.sidebar-product-info {
  @include clearfix;
  min-height: 60px;
  padding: 20px;
  background-color: $brand-primary-dark;
  border-radius: $border-radius-large;
  color: #FFFFFF;

  h3 {
    font-size: $font-size-h1;
    color: #FFFFFF;
  }
}

.woocommerce-MyAccount-navigation {
  ul {
    border-radius: $border-radius-small;
    box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, .15);
    list-style-type: none;
    padding: 20px;
    margin-bottom: 20px;

    li {
      margin-bottom: 15px;
      border-bottom: 1px solid $hr-color;
      padding-bottom: 3px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.woocommerce-MyAccount-content {
  padding: 20px 20px 10px;
  box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, .15);
  margin-bottom: 20px;
  border-radius: $border-radius-small;
}
