// Glyphicons font path
$icon-font-path:        "../fonts/";
$images-path:           "../images/";

// Colors
$brand-primary:         #00b8d3;
$brand-primary-dark:    #0088ac;
$brand-primary-darker:  #006a88;
$brand-secondary:       #ed1374;
$brand-inverse:         #072839;
$brand-inverse-light:   #004258;
$brand-inverse-lighter: #00445a;
$sidebar-background:    #e4f5fc;
$hr-color:              #d8e3e6;
$hr-color-bg:           #0095bd;
$main-text-color:       #4a4c51;
$footer-text-color:     #646a6c;
$footer-heading-color:  #747b7f;
$link-hover-color:      $brand-secondary;
$link-hover-decoration: none;
$headings-color:        $brand-primary;
$page-header-border-color:    $hr-color;
$tooltip-bg:            $brand-primary;
$tooltip-arrow-width:   3px;
$modal-backdrop-bg:     $brand-inverse;
$input-border-focus:    #cccccc;
$text-color:            $main-text-color;
$gray-light:            #aaaaaa;

// Buttons
$btn-default-color:     #ffffff;
$btn-default-bg:        $brand-primary;
$btn-default-border:    $brand-primary;

$btn-primary-color:     #ffffff;
$btn-primary-bg:        $brand-secondary;
$btn-primary-border:    $brand-secondary;

// Type
$font-family-sans-serif: "Roboto", Arial, sans-serif;

// Grid settings
$main-sm-columns:        12;
$sidebar-sm-columns:     3;
$grid-gutter-width:      20px;
$container-lg:           960px;

// Headings
$font-size-h1:           21px;
$font-size-h2:           21px;
$font-size-h3:           14px;

// Navbar
$navbar-margin-bottom:                    0px;
$navbar-border-radius:                    0px;

$navbar-default-color:                    #ffffff;
$navbar-default-bg:                       $brand-primary;
$navbar-default-border:                   $brand-primary;

$navbar-default-link-color:                #ffffff;
$navbar-default-link-hover-color:          $hr-color;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #ffffff;
$navbar-default-link-active-bg:            $brand-primary-dark;

$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        #ffffff;
$navbar-default-toggle-border-color:       transparent;

$navbar-inverse-color:                      $brand-primary;
$navbar-inverse-bg:                         $brand-inverse;
$navbar-inverse-border:                     transparent;

$navbar-inverse-link-color:                 $brand-primary;
$navbar-inverse-link-hover-color:           #ffffff;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $brand-primary;
$navbar-inverse-link-active-bg:             $brand-inverse;

$navbar-inverse-toggle-hover-bg:           transparent;
$navbar-inverse-toggle-icon-bar-bg:        #ffffff;
$navbar-inverse-toggle-border-color:       transparent;

$navbar-flat-bg:                           $brand-inverse;

// Forms
$form-group-margin-bottom:                  10px;
$border-radius-large:                       7px;
$border-radius-small:                       3px;

// Tables
$table-link-color:                         #00b8d3;
$table-link-hover-color:                   $brand-secondary;

// Remove colors
$remove-btn-color:                         $brand-primary;
$remove-btn-background-color:              $brand-inverse-light;

// Quantity control
$quantity-control-color:                   #ffffff;
$quantity-control-color-hover:             $brand-inverse;
$quantity-control-color-flat:              #888888;
$quantity-control-color-flat-hover:        #00b8d3;
