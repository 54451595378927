// Global styles and bootstrap overrides
* {
  position: relative;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$icon-font-path}roboto-regular-webfont.eot');
  src: url('#{$icon-font-path}roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$icon-font-path}roboto-regular-webfont.woff') format('woff'),
       url('#{$icon-font-path}roboto-regular-webfont.ttf') format('truetype'),
       url('#{$icon-font-path}roboto-regular-webfont.svg#roboto-regular-webfont') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$icon-font-path}roboto-medium-webfont.eot');
  src: url('#{$icon-font-path}roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$icon-font-path}roboto-medium-webfont.woff') format('woff'),
       url('#{$icon-font-path}roboto-medium-webfont.ttf') format('truetype'),
       url('#{$icon-font-path}roboto-medium-webfont.svg#roboto-medium-webfont') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$icon-font-path}roboto-bold-webfont.eot');
  src: url('#{$icon-font-path}roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$icon-font-path}roboto-bold-webfont.woff') format('woff'),
       url('#{$icon-font-path}roboto-bold-webfont.ttf') format('truetype'),
       url('#{$icon-font-path}roboto-bold-webfont.svg#roboto-bold-webfont') format('svg');
  font-weight: 700;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  text-transform: uppercase;
}

h1, h2, h3,
.h1, .h2, .h3 {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 700;
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;
}

.no-click {
  pointer-events: none;
  cursor: default;
}

.row-fluid {
  @include clearfix;
}

.padding-vertical {
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;
}

.text-clip {
  max-width: 100%;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-inverse {
  color: #ffffff;
}

.fa {
  &.fa-md {
    font-size: 16px;
  }
}

.page-header {
  padding-bottom: 0;
  margin: 0 0 1em;
}

.section-header {
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;

  .section-title {
    margin: 5px 0;
  }
}

.product-tab-content {
  @include clearfix;
  margin-bottom: 20px;
}

.product-tab-header {
  @include clearfix;
  max-height: 50px;
  line-height: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid $hr-color;

  &.product-tab-header-right {
    margin-left: -10px;
    padding-left: 10px;
  }

  &.product-tab-header-left {
    margin-right: -10px;
    padding-right: 10px;
  }

  .product-tab-title {
    line-height: inherit;
    margin-bottom: 20px;
  }
}

.owl-nav,
.designer_products .owl-nav {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  width: 100%;

  .owl-prev,
  .owl-next {
    @include fa-icon();
    position: absolute;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    vertical-align: middle;
    background: $brand-primary;
    border: 3px solid #ffffff;
    color: #ffffff;

    &::after {
      display: inline-block;
      position: absolute;
      top: 0;
      width: 34px;
      height: 34px;
      line-height: 34px;
    }
  }

  .owl-prev {
    left: -45px;

    &::after {
      left: 0;
      content: $fa-var-chevron-left;
    }
  }

  .owl-next {
    right: -45px;

    &::after {
      left: 2px;
      content: $fa-var-chevron-right;
    }
  }

  .vam-udesign-owl-nav-control {
    display: none;
  }
}

.designer_products .owl-nav {
  margin-left: -10px;
}

.owl-vam-udesign-classic {
  .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent;


    .owl-dot {
      display: inline-block;
      zoom: 1;
      width: 20px;
      height: 20px;
      border: 3px solid #FFFFFF;
      margin: 5px;
      border-radius: 10px;
      background-color: $brand-primary;
      box-shadow: 0 0 1px 0 rgba(0,0,0,.1);

      &.active {
        background-color: $brand-secondary;
      }
    }
  }
}

.vam-udesign-classic-callout {
  background-color: $brand-inverse;

  .vam-udesign-classic-callout-box {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .vam-udesign-classic-callout-title {
    text-transform: none;
  }

  .vam-udesign-classic-callout-description {
    color: #FFFFFF;
  }

  .vam-udesign-classic-callout-image {
    width: 60px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    color: $brand-secondary;
    border: 3px solid $brand-inverse-light;
    border-radius: 30px;
  }

  .vam-udesign-classic-callout-font {
    font-size: 3em;
  }
}

.vam-udesign-classic-featured {
  background-color: $sidebar-background;

  .container {
    padding: 30px 50px;

    &.fixed {
      padding: 30px 0;
    }

    .featured-product-loop-carousel {
      .owl-stage {
        margin: 0 auto;
      }
    }
  }

  .default-theme.woocommerce-products-carousel-all-in-one {
    .woocommerce-products-carousel-all-in-one-container {
      &,
      &:hover {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.modal-dialog {
  .close {
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    color: #FFFFFF;
    background-color: #AAAAAA;
    margin-top: 0;
    border: 2px solid #FFFFFF;
    border-radius: 15px;
    padding: 0;
    opacity: 1;
    z-index: $zindex-modal;

    &:hover {
      background-color: #999999;
    }

    span {
      top: -4px;
      font-size: 16px;
    }
  }

  .modal-header {
    .close {
      top: -30px;
      right: -30px;
    }
  }
}

.modal-body {
  min-height: 50vh;
}

.modal-body-scroller {
  width: 100%;
  max-height: 75vh;
  overflow-y: auto;
}

@media (min-width: $screen-sm-min) {
  .modal-dialog {
    margin: 60px auto;
  }
  .vam-udesign-classic-callout {
    .vam-udesign-classic-callout-box {
      text-align: left;
    }
  }
}


@media (min-width: $screen-md-min) {

}

.pp_gallery {
  margin-top: -70px;

  .pp_arrow_next,
  .pp_arrow_previous {
    top: 17px;
  }

  ul {
    height: auto;

    a {
      height: auto;
    }
  }
}

.pp_description {
  float: left;
}
