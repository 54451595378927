.page-template-layout-full-width,
.page-template-layout-front-page {
  > .container {
    width: 100%;
  }

  .main {
    padding-left: 0;
    padding-right: 0;

    .page-header {
      padding-left: 10px;
      padding-right: 10px;
    }

    .popup-container {
      padding-left: 10px;
      padding-right: 10px;
    }

    .vam-udesign-classic-recent,
    .vam-udesign-classic-best-selling {
      .container {
        .section-header {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

body.blog {
  .main,
  .sidebar-primary .main {
    box-shadow: 2px 2px 27px 0 rgba(50, 50, 50, 0.15);
    padding: 20px;
    border-radius: $border-radius-small;

    @media (max-width: $grid-float-breakpoint) {
      margin-top: 20px;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .vam-udesign-classic-slider-wrap {
    margin-top: -130px;
  }

  .udesign-header-flat {
    .vam-udesign-classic-slider-wrap {
      margin-top: -40px;
    }
  }
}

@media (max-width: $grid-float-breakpoint) {
  .vam-udesign-classic-slider-wrap {
    margin-top: -15px;
  }
}
