// Search form
.input-sm.input-round {
  border-radius: 15px;
  min-height: 30px;
  font-size: 14px;
}

// Inputs
input[type="text"],
input[type="email"]
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="search"],
.woocommerce .input-text,
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  box-shadow: none;
  min-height: 38px;
  font-size: 15px;

  &:focus {
    border-color: $brand-primary;
    box-shadow: none;
  }
}

// Reset checkbox
.checkbox,
.woocommerce-form__label-for-checkbox {
  position: relative !important;
  padding-left: 25px !important;

  input[type="checkbox"] {
    width: 16px !important;
    height: 16px !important;
    position: absolute !important;
    left: 0 !important;
    right: auto !important;
    top: 50% !important;
    margin: -8px 0 0 !important;
    display: block !important;
  }
}

.checkbox {
  margin-left: 0 !important;
}

.woocommerce form {
  .form-row {
    padding: 4px 0;

    .select2-container .select2-selection {
      border-color: #ccc;
      height: 38px;
      line-height: 38px;
      .select2-selection__rendered {
        line-height: 38px;
      }

      .select2-results__option,
      .select2-selection__rendered {
        font-size: 15px;
      }

      .select2-selection__arrow {
        top: 6px;
      }
    }
  }
}

.woocommerce {
  form.woocommerce-ordering,
  form.woocommerce-products-per-page {
    margin-right: 10px;

    select {
      min-height: 30px;
      font-size: 15px;
      color: #555555;
      background-color: transparent;
      border: 1px solid $input-border-focus;
      box-shadow: none;
      border-radius: 5px;
      padding-top: 1px;
    }
  }

  td.product-quantity {
    min-width: 170px;
  }
}

.create-account {
  .woocommerce-form__label-for-checkbox {
    #createaccount.woocommerce-form__input-checkbox {
      margin-top: 6px;
    }

    span {
      margin-left: 20px;
    }
  }
}

.woocommerce-account,
.woocommerce-checkout {
  .woocommerce-form__label-for-checkbox {
    margin: 0 10px 0 5px;

    #rememberme {
      vertical-align: middle;
      margin-top: -5px;
    }
  }
}

.navbar-login .radio, .navbar-login .checkbox {
  margin-top: 7px;
  padding-top: 0;
  min-height: 0;
}
