.content-info {
  color: $footer-text-color;
  background-color: $sidebar-background;

  ul,
  ol {
    @extend .list-inline;
  }

  .widget {
    h3 {
      color: $footer-heading-color;
    }
  }

  .footer-top {
  }

  .footer-bottom {
    color: #ffffff;
    background-color: $brand-primary-dark;

    .widget {
      h1, h2, h3, h4, h5, h6 {
        color: #fff;
      }

      a {
        color: #fff;

        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }

  .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .site-logo {
    &.visible {

    }
  }

  .site-title {

  }

  .site-description {

  }

  .widget_text {
    margin-left: 10px;
    margin-right: 10px;
  }

  .vam-udesign-classic-social-networks {
    .display-icons {
      a {
        i {
          color: $btn-default-color;

          &:hover {
            color: $brand-primary;
          }

          &.fa-inverse {
            color: $brand-primary;

            &:hover {
              color: $brand-secondary;
            }
          }
        }

        i.fa.fa-circle-o,
        i.fa.fa-square-o,
        i.fa-circle-thin {
          color: $btn-default-color
        }
      }
    }
  }
}
