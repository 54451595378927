// Grid system
.main {
  margin-top: 10px;
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
    @include make-sm-column-push($sidebar-sm-columns);
  }
}
.sidebar {
  margin-top: 10px;
  @include make-sm-column($sidebar-sm-columns);
  @include make-sm-column-pull($main-sm-columns - $sidebar-sm-columns);
}

.fluid-grid-list {
  margin: 0 auto;
  opacity: 0;

  .fluid-grid-list-item {
    float: left;

    .design-box-thumb {
      img {
        width: auto;
      }
    }
  }
}

.container {
  &.fluid {
    width: 100%;
  }
}
