.btn {
  text-transform: uppercase;
}

.btn-round {
  border-radius: 17px;
}

.btn-sm.btn-round {
  border-radius: 15px;
}

.btn-lg.btn-round {
  border-radius: 23px;
}

.btn-bordered {
  &,
  &:active,
  &:hover {
    border: 2px solid #FFFFFF;
  }
}