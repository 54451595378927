.udesign-designer-page {
  font-size: 18px;

  .udesign {
    .designer-wrapper {
      .designer-header {
        padding: 0 10px;

        .designer-header-row {
          .designer-button {
            @include button-variant($btn-default-color, $btn-default-bg, #FFFFFF);
            padding: 10px 20px;
            font-size: 14px;

            &.alt {
              @include button-variant($btn-default-color, $btn-primary-bg, #FFFFFF);
            }
          }

          .designer-option-color {
            margin-right: 15px;
          }

          .designer-option-label {
            font-size: 16px;
            text-transform: uppercase;
          }
        }
      }

      .add-to-cart-popup {
        div.product {
          form.cart {
            .attribute-variations {
              border: 1px solid #dedede;
              border-radius: 3px;

              .h3 {
                color: $text-color;
              }

              .btn-size-guide {
                color: $brand-primary;

                &:hover {
                  color: $link-hover-color;
                }
              }

              .variation-list {
                .variation {
                  .swatch-wrapper {
                    a {
                      box-shadow: inset 0 0 0 1px #D6DADF;
                    }
                  }
                }
              }
            }

            .single_variation_wrap {
              .single_variation {
                float: left;

                .woocommerce-variation-price, p.price, span.price {
                  color: $text-color;
                }
              }

              .variations_button {
                float: left;

                .quantity {
                  .quantity-control {
                    color: #555555;
                    background-color: #ffffff;
                    border-color: $input-border-focus;

                    &:hover {
                      color: $brand-primary;
                    }
                  }
                  .input-text.qty {
                    color: #555555;
                    background-color: #ffffff;
                    border-color: $input-border-focus;
                    border-left-color: #ffffff;
                    border-right-color: #ffffff;
                  }
                }

                &.udesign-customize-button {
                  float: right;
                  clear: both;
                }
              }

              .added_to_cart {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
