.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
  background-color: transparent;
}

.primary-nav {
  .navbar-nav {
    >li {
      >a {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 8px;
        padding-right: 8px;

        &.dropdown-icon-left {
          padding-left: 46px;

          .dropdown-icon {
            left: 8px;
          }
        }
      }

      &.open {
        >a,
        >a:hover {
          background-color: $brand-primary-dark;
        }

        .dropdown-icon {
          background-color: $brand-primary;
        }
      }
    }

    .dropdown-icon {
      position: absolute;
      top: 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 15px;
      color: #FFFFFF;
      background-color: $brand-primary-dark;
    }
  }

  .dropdown-menu {
    min-width: 350px;
    color: #FFFFFF;
    background-color: $brand-primary-dark;
    border: 0;

    > li {
      > a {
        color: #FFFFFF;
        background-color: transparent;
      }
    }

    .form-horizontal {
      padding: 15px 25px 5px;
      border-top: 1px solid $hr-color-bg;

      &:first-child {
        border: 0;
      }
    }
  }
}

.search-form {
  .search-query {
    width: 100%;
  }

  .search-btn {
    position: absolute;
    top: -2px;
    right: -5px;
    color: $brand-secondary;

    &:hover,
    &:focus {
      color: $brand-primary;
      outline: none;
    }
  }
}

.header-bottom {
  .container-secondary-nav {
    padding: 0px;
  }

  .navbar {
    min-height: 0;
    border-width: 0;
  }

  .navbar-nav {
    &>li>.dropdown-menu {
      background-color: $brand-inverse;
      border-color: $brand-inverse;

      &>li>a {
        background-color: inherit;
        color: $brand-primary;
        text-transform: capitalize;

         &:hover {
          color: #ffffff;
         }
      }

      &>li.cat-item-none {
        color: $gray-light;
        padding: 3px 20px;
      }
    }

    &>li,
    &>li.active,
    &>li.open {
      &>a {
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
}

.header-bottom.udesign-header-flat {
  background: $navbar-flat-bg;

  .navbar-inverse {
    background: transparent;
  }

  .container-secondary-nav {
    width: 100%;
    height: auto;
    padding: 0;
    font-size: 16px;
  }

  .navbar-nav {
    & > li {
      & > .dropdown-menu {
        background-color: $navbar-flat-bg;
        border-color: $navbar-flat-bg;
      }
    }

    .open > a {
      background-color: $navbar-flat-bg;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .header-top.udesign-header-flat {
    .navbar-collapse {
      padding-right: 0;
    }

    .primary-nav {
      .navbar-nav {
        .dropdown-menu {
          border-radius: 0 0 $border-radius-small $border-radius-small;
        }
      }
    }
  }

  .navbar-form {
    float: left;
    margin-top: 9px;
  }

  .navbar-nav {
    .dropdown-menu {
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .primary-nav & {
      >li {
        >a {
          .dropdown-icon {
            top: 8px;
          }
        }
      }

      .dropdown-menu {
        > li {
          border-top: 1px solid $hr-color-bg;

          &:first-child {
            border: 0;
          }

          > a {
            padding: 7px 20px;

            > i {
              color: $brand-primary;
              padding-right: 10px;
            }

            &:hover {
              > i {
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
  .header-bottom {
    .container-logo {
      height: 120px;
      width: 20%;
      padding: 0px;
    }

    .container-secondary-nav {
      height: 120px;
      width: 80%;
      padding: 30px 20px 30px 0px;
    }

    .navbar {
      min-height: 58px;
      border-radius: 30px;
      padding: 9px 7px;
      border-width: 1px;
    }

    .secondary-nav {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .navbar-nav {
      width: 100%;

      .dropdown.mega {
        position: initial;
      }

      &>li {
        &>.dropdown-menu {
          width: 100%;
          border-top-width: 10px;
          padding-bottom: 10px;
        }
      }

      &>li,
      &>li.active,
      &>li.open {
        padding: 0 5px;
        float: none;
        display: table-cell;
        width: 1%;

        &>a {
          text-align: center;
          padding: 10px;
          color: $brand-inverse;
          background-color: $brand-primary;
          box-shadow:  inset 0px 1px 0px 0px lighten($brand-primary, 20%);
          border-radius: 20px;

          &.dropdown-toggle {
            padding-right: 35px;

            .caret {
              @include fa-icon();
              width: 20px;
              height: 20px;
              border-radius: 10px;
              padding: 0;
              border: 0;
              position: absolute;
              top: 10px;
              right: 10px;
              font-size: 10px;
              line-height: 20px;
              color: $brand-primary;
              background-color: #ffffff;

              &:after {
                content: $fa-var-chevron-down;
              }
            }
          }

          &:hover,
          &:focus {
            color: #ffffff;
            background-color: $brand-secondary;
            box-shadow:  inset 0px 1px 0px 0px lighten($brand-secondary, 20%);

            &.dropdown-toggle .caret {
              color: $brand-secondary;
            }
          }
        }
      }

      &>li.open {
        &>a {
          color: #ffffff;
          background-color: $brand-secondary;
          box-shadow:  inset 0px 1px 0px 0px lighten($brand-secondary, 20%);

          &.dropdown-toggle .caret {
            line-height: 18px;
            color: $brand-secondary;

            &:after {
              content: $fa-var-chevron-up;
            }
          }
        }
      }
    }

    &.udesign-header-flat {
      margin-bottom: 30px;

      .navbar {
        min-height: 0;
        padding: 0;
        border-width: 0;
      }

      .dropdown,
      .menu-item {
        a {
          background-color: transparent;
        }
      }

      .dropdown-menu {
        left: 0;
        right: auto;
        border-radius: 0 0 $border-radius-small $border-radius-small;
      }

      .navbar-nav {
        &>li,
        &>li.active,
        &>li.open {
          display: block;
          float: left;
          width: auto;
          padding: 0;

          &>a {
            background: transparent;
            box-shadow: none;
            color: #fff;
            border-radius: 0;
            padding: 15px 35px 15px 15px;

            .caret {
              top: 15px;
              background: transparent;

              &:after {
                color: #fff;
              }
            }

            &:hover,
            &:focus {
              background-color: $brand-secondary;
            }
          }

          &.menu-item>a {
            padding-right: 15px;
          }
        }
      }
    }
  }
}

.banner {
  z-index: $zindex-navbar-fixed;

  .site-logo-xs {
    padding: 5px 0;
    max-height: $navbar-height;
    top: 50%;
    transform: translate(0%, -50%);
  }

  .logo {
    height: 100%;

    .site-logo {
      padding: 0 10px 0 0;
      height: 100%;
      z-index: 999;

      a {
        display: block;
        height: 100%;

        img {
          max-height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .site-title {
      display: table;
      height: 50%;
      margin: 0;

      a {
        display: table-cell;
        vertical-align: bottom;
        text-transform: none;
      }
    }

    .site-description {
      height: 50%;
      margin: 0;
      padding-top: 5px;

      span {
        text-transform: none;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .header-top.udesign-header-flat {
    text-align: center;

    .navbar-header {
      text-align: center;
      float: none;
    }

    .navbar-brand {
      float: none;
      margin-top: 10px;
      display: inline-block;
    }

    .navbar-right {
      float: none !important;
      display: inline-block !important;
    }
  }
}

@media (max-width: $grid-float-breakpoint) {
  .primary-nav .navbar-nav > li > a,
  .header-bottom .navbar-nav > li > a {
    font-size: 16px;
  }
}
